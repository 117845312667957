/* @import url("./style/cssFileName.css"); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.form-wrapper {
  background-color: #222629;
  color: #fff;
}

input.react-international-phone-input {
  width: 100%;
}

.pass-toggle {
  text-align: end;
  position: absolute;
  right: 25px;
  top: 40px;
}

.pass-toggle1 {
  text-align: end;
  position: absolute;
  right: 25px;
  top: 48px;
}

.pass-toggle-reg {
  text-align: end;
  position: absolute;
  right: 25px;
  top: 40px;
}

.password-toggle-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.logo-container {
  flex: 0 0 auto;
}

.logo {
  height: 65px;
  width: 100px;
  cursor: pointer;
}

.ant-upload.ant-upload-select {
  margin: 0 15px 0 0;
}

.ant-form-item-explain-error {
  text-align: start;
}

span.ant-upload-list-item-name {
  word-break: break-all;
  max-width: 170px;
}

.profile-edit {
  border: none;
  background: #17c1e8 !important;
  color: white;
  width: 116px;
  height: 35px;
  border-radius: 7px;
  align-items: right;
  margin: 0px 25px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* //////////////////////////////////////////////////  */
.profile-update {
  border: none;
  background: #58838d !important;
  color: white;
  width: 116px;
  height: 35px;
  border-radius: 7px;
  align-items: right;
  margin: 0px 79px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-body {
  padding: 30px 30px 0 30px !important;
}

.ant-modal-footer {
  margin: 0 0 0 0 !important;
  padding: 0 30px 30px 30px !important;
}

.profile-section .card-body {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 40px 40px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.profile-section .card.mb-4 {
  border: none !important;
  background: transparent !important;
}

.content-dasboard h1.ant-typography.css-dev-only-do-not-override-98ntnt {
  color: #3e3e3e;
}

.button.ant-btn.css-dev-only-do-not-override-1drr2mu.ant-btn-default {
  float: left;
}

.active {
  background-color: #000;
}

.resend-otp {
  text-align: center;
  margin-top: 20px;
}

.btn-100 {
  width: 100%;
}

/* ::-webkit-scrollbar {
  width: 5px;
} */

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body {
  background: #f8f9fa;
  font-family: "Jost", sans-serif;
}

.side-bar {
  min-height: 100vh;
  max-height: calc(100% - 0px);
  transform: translateX(0%);
  width: 240px;
  position: fixed;
  left: 0;
  top: 0;
  transition: 0.5s;
  z-index: 1;
  overflow: auto;
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
}
/* ul.link-ul a {
  color: #fff !important;
} */

.login_background_css {
  background: url("./Utils/Images/aiimage.jpg");
  background-size: auto;
  background-color: #222629;
  min-height: 100vh;
}

.open-class .side-bar {
  left: -260px;
}

.open-class .dasboard-all .container-fluid {
  padding-left: 30px;
}

.logo-sec h1 {
  color: #17c1e8 !important;
}

.link-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* .link-ul li a {
  font-size: 16px;
  color: #67748e;
  text-decoration: none;
  display: block;
  padding: 10px 25px;
  border-radius: 10px;
  margin: 0 0 5px 0;
  cursor: pointer;
  transition: 0.3s;
} */

.link-ul li a {
  font-size: 16px;
  color: #67748e;
  text-decoration: none;
  display: block;
  padding: 10px 25px;
  border-radius: 10px;
  margin: 0 0 5px 0;
  cursor: pointer;
  transition: 0.3s;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

a.active {
  cursor: pointer !important;
}

.link-ul li a.active,
.link-ul li a:hover {
  background-color: #17c1e8;
  color: #fff;
}

.link-div {
  padding: 21px 15px;
}

.logo-top {
  max-width: 140px;
  margin: auto;
  display: block;
  margin-bottom: 25px;
}

.dasboard-all .container-fluid {
  padding-left: 270px;
  padding-right: 30px;
  transition: 0.5s;
}

.link-ul li a i {
  margin: 0 12px 0 0 !important;
}

.dasboard-all.top-header {
  padding: 5px 0 5px 0;

  background: #17c1e8;
  background: #f8f9fa;
  border-bottom: 1px solid #efefef;
}

.header-flex ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

button.w-100.p-2.gmail_button_css {
  background: #141727;
  color: white;
  width: 60% !important;
  font-size: 13px;
  margin: auto;
  display: block;
  padding: 12px 15px !important;
  border-radius: 0.25rem !important;
}

.insta_button_css {
  background: #141727;
  color: white;
  width: 60% !important;
  font-size: 13px;
  margin: auto;
  display: block;
  padding: 12px 15px !important;
  border-radius: 0.25rem !important;
}

.tog-bt {
  display: block;
  padding: 0px 10px;
  border-radius: 10px;
  margin: 0 0 0px 0;
  transition: 0.3s;
  background: #98bdff;
  color: #fff;
  border: none;
  height: 35px;
  width: 45px;
  text-align: center;
  line-height: 35px;
}

.header-flex ul li button {
  background: #17c1e8 !important;
  font-size: 14px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  padding: 0 15px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.line-1 {
  height: 2px;
  width: 30px;
  background: #fff;
  display: block;
  margin: 4px 0;
  border-radius: 23px;
}

.log-out-bt {
  cursor: pointer;
  padding: 5px 30px 5px 30px;
  font-size: 15px;
  font-weight: 400;
  height: 40px;
  font-family: "Jost", sans-serif;
  border-radius: 100px;
  background: rgb(123 144 183);
  border: none;
  color: #fff;
}

/* .top-header i.fa-solid.fa-bars {
  display: none;
} */

.top-header .fa-solid.fa-bars {
  display: none;
}

/* .open-class .top-header i.fa-solid.fa-caret-left {
  display: none;
} */

.open-class .top-header .fa-solid.fa-caret-left {
  display: none;
}

/* .open-class .top-header i.fa-solid.fa-bars {
  display: block;
} */

.open-class .top-header .fa-solid.fa-bars {
  display: block;
}

.last-table {
  overflow: auto;
}

.last-table table {
  width: 100%;
  min-width: 800px;
}

.last-table table thead tr th {
  font-size: 16px;
  color: #ffffff;
  padding: 15px 15px;
  background: #76b51b;
  font-weight: bold;
}

.last-table table tbody tr td {
  font-size: 16px;
  color: #535353;
  padding: 15px 15px;
  background: #ffffff;
  font-weight: 400;
}

.last-table table thead tr th:nth-child(1) {
  border-top-left-radius: 12px;
}

.last-table table thead tr th:last-child {
  border-top-right-radius: 12px;
}

.last-table table tbody tr:last-child td:nth-child(1) {
  border-bottom-left-radius: 12px;
}

.last-table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 12px;
}

.content-dasboard {
  padding: 10px 0;
}

.last-table table tbody tr:nth-child(even) td {
  background: #76b51b14;
}

.last-table table tbody tr:hover td {
  background: #76b51b38;
}

.search-input .same-input {
  border: 1px solid #f7f7f7;
  background: #fff;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  font-size: 16px;
  padding: 0 15px;
  max-width: 140px;
  margin: 0 0 0 0;
  color: #535353;
}

.search-input .same-input:hover,
.search-input .same-input:focus {
  outline: 2px solid #76b51b;
}

.input-ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.input-ul li {
  margin: 0 15px 15px 0;
}

.bt-search {
  border: 1px solid #f7f7f7;
  background: #76b51b;
  width: 100%;
  height: 50px;
  border-radius: 12px;
  font-size: 16px;
  padding: 0 15px;
  max-width: 160px;
  margin: 0 0 0 0;
  color: #ffffff;
}

.all-label {
  color: #535353;
  font-size: 16px;
  margin: 0 5px 0 0;
}

.profile-details {
  display: flex;
  justify-content: center;
}

.form-size {
  width: 100%;
  max-width: 600px;
  background: #fff;
  padding: 30px;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

/* .form_custom_css{
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
} */

.update-btn {
  width: 100%;
  color: #fff;
  background-color: #17c1e8 !important;
}

@media (min-width: 768px) {
  .abs-bk {
    display: none;
  }
}

/* ***********************MOBILE RESPONSIVE CSSS******************************* */
@media (max-width: 767px) {
  .dasboard-all .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .resp_add_social_info {
    flex-direction: column;
    align-items: center !important;
  }

  .resp_add_social_info button {
    background: rgb(64, 93, 230);
    color: white;
    width: 100% !important;
    padding: 10px;
    font-size: 13px;
  }

  .resp_height {
    height: 495px !important;
  }

  .open-class .dasboard-all .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .side-bar {
    left: -270px;
  }

  .open-class .side-bar {
    left: 0px;
  }

  /* .abs-bk i {
    color: #fff;
    font-size: 15px;
    position: fixed;
    right: -10px;
    top: 110px;
    background-color: #17c1e8;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 30px;
    line-height: 20px;
  } */

  .abs-bk .icon {
    color: #fff;
    font-size: 15px;
    position: fixed;
    right: -10px;
    top: 110px;
    background-color: #17c1e8;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 30px;
    line-height: 20px;
  }

  .resp_otp_css {
    border-radius: 15px;
    z-index: 99999;
    width: 321px !important;
  }

  .resp_otp_css_form input {
    width: 243px !important;
  }

  .Chat_bot_css {
    height: 80vh !important;
    border-radius: 20px !important;
    padding: 20px !important;
    position: relative !important;
  }

  .chat_bot_input_css input {
    padding: 10px !important;
    border-radius: 8px !important;
    border: 1px solid darkslategrey !important;
    margin: 0 0 10px 0;
  }

  .chat_bot_button_css button {
    border-radius: 8px !important;
    width: 260px !important;
    padding: 10px !important;
    background: #f3797e !important;
    color: white;
    border: none !important;
  }
}

.login-22 {
  top: 0;
  width: 100%;
  text-align: center;
  bottom: 0;
  opacity: 1;
  z-index: 999;
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

.register_css {
  margin-top: 0px !important;
}

.form_css {
  width: 100% !important;
  max-width: 580px;
  margin: 0 !important;
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 30px 30px !important;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.d-flex.justify-content-center.gap-3.my-5.resp_add_social_info {
  flex-wrap: wrap;
}

.instagram_input_css {
  font-size: 13px;
  padding: 8px;
}

.instagram_logo_css {
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  color: white;
  border-radius: 24px;
  padding: 5px;
  margin-top: 20px;
}

.Label_css {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  font-family: math;
}

.login-22 a {
  text-decoration: none;
}

.login-22 h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Jost", sans-serif;
}

.login-22 .form-section {
  max-width: 550px;
  margin: 0 auto;
}

.login-22 .form-section .details {
  padding: 80px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #f5f5f5;
  position: relative;
  z-index: 0;
}

.login-22 .form-check-input:checked {
  display: none;
}

.login-22 .form-section p {
  color: #535353;
  margin-bottom: 0;
  font-size: 16px;
}

.login-22 .form-section p a {
  color: #535353;
}

.login-22 .form-section ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px;
}

.login-22 .form-section .social-list li {
  display: inline-block;
  margin-bottom: 5px;
}

.login-22 .logo-2 img {
  margin-bottom: 10px;
  height: 70px;
}

.login-22 .form-section .thembo {
  margin-left: 4px;
}

.login-22 .form-section h3 {
  margin: 0 0 40px;
  font-size: 25px;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  color: #040404;
}

.login-22 .form-section .form-group {
  margin-bottom: 40px;
}

.login-22 .form-section .form-box {
  float: left;
  width: 100%;
  position: relative;
  text-align: left;
}

.login-22 .form-section .form-control {
  font-size: 16px;
  outline: none;
  background: transparent !important;
  color: #535353;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: solid 2px #bdbdbd;
}

.login-22 .form-section .btn-md {
  cursor: pointer;
  padding: 12px 50px 11px 50px;
  font-size: 15px;
  font-weight: 400;
  height: 55px;
  font-family: "Jost", sans-serif;
  border-radius: 100px;
}

.login-22 .form-section button:focus {
  outline: none;
  outline: 0 auto -webkit-focus-ring-color;
}

.login-22 .form-section .btn-theme.focus,
.btn-theme:focus {
  box-shadow: none;
}

.login-22 .form-section .terms {
  margin-left: 3px;
}

.login-22 .form-section input[type="checkbox"],
input[type="radio"] {
  margin-top: 4px;
}

.login-22 .form-section .checkbox a {
  float: right;
}

.login-22 .form-section .checkbox {
  margin-bottom: 30px;
  font-size: 15px;
}

.login-22 .form-section .form-check {
  float: left;
  margin-bottom: 0;
  padding-left: 0;
}

.login-22 .form-section .form-check a {
  color: #535353;
  float: right;
}

.login-22 .form-section .form-check-input {
  position: absolute;
  margin-left: 0;
}

.login-22 .form-section .form-check label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 2px;
  margin-left: -25px;
  border-radius: 0px;
  background: #f5f5f5;
  border: solid 2px #bdbdbd;
}

.login-22 .form-section .form-check-label {
  padding-left: 25px;
  margin-bottom: 0;
  font-size: 16px;
  color: #535353;
}

.login-22 .form-section input[type="checkbox"]:checked + label:before {
  font-weight: 600;
  color: #fff;
  line-height: 14px;
  font-size: 12px;
  content: "\2713";
  padding-left: 3px;
}

.login-22 .form-section .form-control:focus {
  outline: none;
  box-shadow: none;
}

.login-22 .form-section .checkbox-theme input[type="checkbox"]:checked + label::before {
  color: #fff;
  background: #76b51b;
  border: solid 2px #76b51b;
}

.login-22 .form-section .btn-theme {
  background: #76b51b;
  border: none;
  color: #fff;
}

.login-22 .form-section .btn-theme:hover {
  background: #7dc11c;
}

.login-22 .form-section a.forgot-password {
  font-size: 16px;
  color: #535353;
}

.login-22 .social-list {
  float: left;
}

.login-22 .social-list span {
  margin-right: 5px;
  font-weight: 500;
  font-size: 15px;
  color: #535353;
}

.login-22 .social-list a {
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  display: inline-block;
  font-size: 15px;
  color: rgb(255, 255, 255);
  margin: 0 2px 2px 0;
  border-radius: 50px;
}

.login-22-bodycolor .ripple-background {
  z-index: -999 !important;
}

.login-22-bodycolor .ripple-background .circle {
  position: absolute;
  border-radius: 50%;
  animation: ripple 15s infinite;
}

.login-22-bodycolor .ripple-background .small {
  width: 200px;
  height: 200px;
  left: -100px;
  top: -100px;
  z-index: -999;
}

.login-22-bodycolor .ripple-background .medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
  z-index: -999;
}

.login-22-bodycolor .ripple-background .large {
  width: 600px;
  height: 600px;
  left: -300px;
  top: -300px;
  z-index: -999;
}

.login-22-bodycolor .ripple-background .xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  top: -400px;
  z-index: -999;
}

.login-22-bodycolor .ripple-background .xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  z-index: -999;
}

.login-22-bodycolor .ripple-background .shade1 {
  background: #0000ff26;
}

.login-22-bodycolor .ripple-background .shade2 {
  background: #b9b9ff;
}

.login-22-bodycolor .ripple-background .shade3 {
  background: #0000ff26;
}

.login-22-bodycolor .ripple-background .shade4 {
  background: #0a58ca;
}

.login-22-bodycolor .ripple-background .shade5 {
  background: #0000ff26;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

.login-22 .facebook-bg {
  background: #4867aa;
}

.login-22 .facebook-bg:hover {
  background: #3b589e;
}

.login-22 .twitter-bg {
  background: #33ccff;
}

.login-22 .twitter-bg:hover {
  background: #2abdef;
}

.login-22 .google-bg {
  background: #db4437;
}

.login-22 .google-bg:hover {
  background: #cc4437;
}

.login-22 .linkedin-bg {
  background: #2392e0;
}

.login-22 .linkedin-bg:hover {
  background: #1c82ca;
  color: #fff;
}

.ripple-background {
  z-index: -999 !important;
}

.ripple-background .circle {
  position: absolute;
  border-radius: 50%;
  animation: ripple 15s infinite;
}

.ripple-background .small {
  width: 200px;
  height: 200px;
  left: -100px;
  top: -100px;
  z-index: -999;
}

.ripple-background .medium {
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
  z-index: -999;
}

.ripple-background .large {
  width: 600px;
  height: 600px;
  left: -300px;
  top: -300px;
  z-index: -999;
}

.ripple-background .xlarge {
  width: 800px;
  height: 800px;
  left: -400px;
  top: -400px;
  z-index: -999;
}

.ripple-background .xxlarge {
  width: 1000px;
  height: 1000px;
  left: -500px;
  top: -500px;
  z-index: -999;
}

.ripple-background .shade1 {
  background: #76b51b3d;
}

.ripple-background .shade2 {
  background: #76b51b1f;
}

.ripple-background .shade3 {
  background: #76b51b40;
}

.ripple-background .shade4 {
  background: #0a58ca;
}

.ripple-background .shade5 {
  background: #76b51b73;
}

@keyframes ripple {
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(0.8);
  }
}

/* .dasboard-all.pages {
  min-height: 100vh;
} */

.dasboard-all.pages {
  min-height: calc(100vh - 130px);;
}

.custom-row-bk hr {
  display: none;
}

.custom-row-bk .row {
  padding: 15px 15px;
  background: #f5f7ff;
  border-radius: 12px;
}

.custom-row-bk .row:nth-child(odd) {
  background: #fff;
}

.custom-row-bk .text-muted {
  margin: 0;
}

.footer {
  position: sticky;
  bottom: 0px;
  width: 100%;
  background: #fbfbfb;
}

.footer h1 {
  font-size: 19px;
  padding: 0px;
  font-family: initial;
  text-align: center;
  color: white;
  margin: 0;
  line-height: 50px;
}

@media (max-width: 768px) {
  .form_css {
    background: #fff;
    border: 1px solid #dbdada;
    border-radius: 4px;
    height: 520px;
    margin: auto;
    margin-top: 79px !important;
    padding: 30px;
    width: 100%;
    border-radius: 18px;
  }

  section.background-radial-gradient.overflow-hidden.min-100 {
    padding: 0 25px;
  }

  /* .pass-toggle {
  text-align: end;
  position: absolute;
  right: 40px;
  top: 249px;
} */

  .login-22 .form-section .details {
    padding: 50px 30px;
  }

  .login-22-bodycolor .ripple-background {
    display: none;
  }
}

@media (max-width: 767px) {
  .ripple-background {
    display: none;
  }
}

.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form input {
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.login-form button {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.background-radial-gradient {
  display: flex;
  align-items: center;
  justify-content: center;
}

.min-100 {
  min-height: 100vh;
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -40px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#3d383f, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.bg_login_css {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.bg_register_css {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

@media screen and (max-width: 480px) {
  #radius-shape-2 {
    border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
    bottom: -60px;
    right: 0px !important;
    width: 300px;
    height: 300px;
    background: radial-gradient(#44006b, #ad1fff);
    overflow: hidden;
  }
}

.country_css {
  width: 100%;
  padding: 5px;
  border: 1px solid #dbd1d1;
  border-radius: 4px;
}

.city_css {
  width: 100%;
  padding: 5px;
  border: 1px solid #dbd1d1;
  border-radius: 4px;
}

.Instagram_consent_css_container {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  max-width: 441px;
  background: #fff;
  margin-top: 26px !important;
  margin: auto;
  height: 570px;
  border-radius: 7px;
  padding: 30px;
  border: 1px solid #dbdada;
}

.Instagram_consent_css_container h1 {
  text-align: center;
}

.Instagram_consent_css_container p {
  margin-bottom: 20px;
}

.Instagram_consent_css_container button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #405de6;
  color: #fff;
  width: 50%;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Instagram_consent_css_container button:hover {
  background-color: #577eea;
}

.Revoke_css_container {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  max-width: 441px;
  background: #fff;
  margin-top: 56px !important;
  margin: auto;
  height: 520px;
  border-radius: 7px;
  padding: 30px;
  border: 1px solid #dbdada;
}

.Revoke_css_container h1 {
  text-align: center;
}

.Revoke_css_container p {
  margin-bottom: 20px;
}

.Revoke_css_container button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  /* background-color: #FF5A60; */
  background-color: #405de6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.Revoke_css_container button:hover {
  /* background-color: #FF7B81; */
  background-color: #577eea;
}

.form_button_css:hover {
  background-color: #577eea !important;
}

.delete_user_css_container {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  max-width: 441px;
  background: #fff;
  margin-top: 38px !important;
  margin: auto;
  height: 520px;
  border-radius: 4px;
  padding: 30px;
  border: 1px solid #dbdada;
}

.delete_user_css_container h1 {
  text-align: center;
}

.delete_user_css_container p {
  margin-bottom: 20px;
}

.delete_user_css_container button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  /* background-color: #FF5A60; */
  background-color: #405de6;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete_user_css_container button:hover {
  /* background-color: #FF7B81; */
  background-color: #577eea;
}

.footer_custom_css {
  position: sticky;
  bottom: 0px;
  padding: 10px;
  width: 100%;
  background: #fbfbfb;
}

.footer_custom_css h1 {
  font-size: 19px;
  padding: 14px;
  font-family: initial;
  text-align: center;
  color: white;
  height: 10px;
}

.privacyPolicy_css {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 40px 40px;
  border: none;
  outline: none;
  border-color: transparent;
  border-radius: 18px;
}

.privacyPolicy_css h1 {
  color: #17c1e8;
  margin: 0 0 22px 0;
  font-size: 40px !important;
}

.privacyPolicy_css h5,
.privacyPolicy_css h3 {
  color: #141727 !important;
  margin: 0 0 22px 0;
}

.privacyPolicy_css p {
  margin: 0 0 22px 0;
  color: #67748e;
}

li.text-list {
  color: #67748e;
}
.tiktok_css {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 30px 30px;
  border: none;
  outline: none;
  border-color: transparent;
  border-radius: 18px;
  width: 50%;
  margin: auto;
}

.faq_css {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 40px 40px;
  border: none;
  outline: none;
  border-color: transparent;
  border-radius: 18px;
  width: 50%;
  margin: auto;
  margin-top: 65px;
}

.faq_css h1 {
  color: #17c1e8;
  margin: 0 0 22px 0;
  font-size: 40px !important;
}

.faq_css h5,
.faq_css h3 {
  color: #141727 !important;
  margin: 0 0 22px 0;
}

.faq_css p {
  margin: 0 0 22px 0;
  color: #67748e;
}

/* **************CHATBOT CSSS*************************** */

/* .Chat_bot_css {

  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%, hsl(218, 41%, 35%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%),
    radial-gradient(1250px circle at 100% 100%, hsl(218, 41%, 45%) 15%, hsl(218, 41%, 30%) 35%, hsl(218, 41%, 20%) 75%, hsl(218, 41%, 19%) 80%, transparent 100%);

  border-radius: 20px;
  padding: 20px;
  position: relative;
  padding-bottom: 110px;
  
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
} */

.Chat_bot_css {
  padding: 20px;
  position: relative;
  padding-bottom: 120px;

  background: white;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.input_css_for_all_placeholder {
  background: #f8f9fa !important;
  min-height: 20px !important;
}

.Chat_bot_cssAiagent {
  padding: 20px;
  position: relative;
  padding-bottom: 90px;
  min-height: calc(100vh - 150px);
  background: white;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.chat_bot_h1_css h1 {
  color: #3e3e3e !important;
  font-size: 40px !important;
  font-weight: 600 !important;
  font-family: fangsong !important;
}

.chat_bot_input_css input {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid darkslategrey;
  width: 100%;
  background: #f5f7ff !important;
}

.chat_bot_button_css.mx-2 button {
  width: 100%;
  background: rgb(243, 121, 126);
  color: white;
  border: none;
}

.chat_bot_button_css button {
  border-radius: 8px;
  width: 150px;
  padding: 10px;
  background: white;
  border: 1px solid darkslategrey;
}

.main_input_css {
  align-items: center;
  background-color: transparent;
  /* border: 1px solid #a9a9a9; */
  border-radius: 12px;
  padding: 10px;
  position: absolute;
  bottom: 20px;
  width: 95%;
  flex-wrap: wrap;
  justify-content: space-between !important;
  margin: auto;
  display: block;
  left: 0;
  right: 0;
}

/* **********Question_css******* */

.Question_css {
  border-radius: 12px;
  /* border: 1px solid darkgrey; */
  padding: 5px;
  background: #f5f7ff;
}

.Question_cssAiagent {
  border-radius: 12px;
  /* border: 1px solid darkgrey; */
  padding: 5px;
  background: #f5f7ff;
  width: fit-content;
  max-width: 70%;
}

.Answer_cssAiagent {
  border-radius: 12px;
  display: flex;
  align-items: start;
  gap: 10px;
  max-width: 70%;
  /* border: 1px solid darkgrey; */
  padding: 15px;
  background: #f5f7ff;
  width: fit-content;
}

.Answer_css {
  border-radius: 12px;
  /* border: 1px solid darkgrey; */
  padding: 15px;
  background: #f5f7ff;
}

.message_css {
  overflow-y: auto;
  height: 295px;
}

.message_cssAiagent {
  overflow-y: auto;
  height: calc(100vh - 440px);
  /* min-height: 100vh; */
}

/* ---------------------------------------------------------------------------------- */

.animated-text {
  font-family: Arial, sans-serif;
  white-space: pre-wrap;
  /* Preserve line breaks */
  overflow: hidden;
  /* Hide overflow content */
}

/* Animation */
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.animated-text::after {
  content: "|";
  /* Cursor */
  display: inline-block;
  width: 0;
  animation: typing 1s steps(44) infinite;
}

/* Hide cursor when text is fully typed */
.animated-text::after {
  animation: none;
}

.chat_bot_input_css {
  width: 50%;
  margin: 0;
}

.chat_bot_button_css.mx-2 {
  width: 23%;
  margin: 0 !important;
  display: flex;
  justify-content: center;
}

/* ******************** MAIL CSSS************************************ */

.mail_css {
  display: flex;
  width: 100%;
  background: red;
  padding: 15px;
  height: 100vh;
}

.Insta_css_message {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.instagram_conatiner_css {
  /* background: #e1e1e1; */
  background: white;
  padding: 20px;
  border-radius: 7px;
  padding: 20px;
  width: 50%;
}

.mail_css_form {
  border: 1px solid darkgrey;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background: white;
}

/* .mail_css_form p {
  word-break: break-all;
} */

.mail_css_form li {
  word-break: break-all;
}

.d-flex.custom-flex-100 .d-flex.justify-content-start.rounded-3.p-2.mb-2.bg-body-tertiary {
  justify-content: space-between !important;
  padding: 15px 15px !important;
}

ul.list-name {
  padding: 20px !important;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

ul.list-name li {
  width: 23%;
  margin: 0 0 10px 0;
}

ul.list-name hr {
  display: none !important;
}

.list-box {
  height: 110px;
  overflow-y: auto;
  padding: 0px;
}

@media (max-width: 1024px) {
  .d-flex.justify-content-start.rounded-3.p-2.mb-2.bg-body-tertiary {
    flex-wrap: wrap;
  }

  .d-flex.justify-content-start.rounded-3.p-2.mb-2.bg-body-tertiary .px-3 {
    padding: 0 !important;
  }

  .wid-100 {
    width: 100%;
  }
}

@media (max-width: 991px) {
  button.viewpost {
    width: 100% !important;
  }

  .cus-m {
    margin-bottom: 15px !important;
  }

  .d-flex.custom-flex-100 {
    flex-wrap: wrap;
  }

  .d-flex.custom-flex-100 .flex-shrink-0 {
    width: 100%;
    margin: 0 0 15px 0;
  }

  .d-flex.custom-flex-100 .flex-grow-1.ms-3 {
    margin: 0 0 0 0 !important;
  }

  .d-flex.custom-flex-100 .wid-100 {
    width: auto;
  }
}

@media (max-width: 767px) {
  .chat_bot_button_css.mx-2 {
    width: 46%;
  }

  .chat_bot_input_css {
    width: 100%;
  }

  .message_css {
    overflow-y: scroll;
    height: 450px !important;
  }

  .instagram_conatiner_css {
    width: 100% !important;
  }
}

.content-dasboard .card-body.p-4 {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 30px 30px !important;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.content-dasboard .card-body.p-4.insta-body {
  padding: 0 !important;
  box-shadow: none !important;
}

.content-dasboard .card.cus-m {
  border: none;
}

.content-dasboard button.my-1.btn.btn-primary {
  background: #f3797e;
  border: none;
  margin: 5px 0 0px 0 !important;
  width: 100%;
}

.content-dasboard .flex-grow-1.ms-3 h5.mb-1 {
  font-weight: bold;
  color: #3e3e3e;
}

.content-dasboard .d-flex.justify-content-start.rounded-3.p-2.mb-2.bg-body-tertiary {
  background: #f5f7ff !important;
}

.content-dasboard p.mb-0 {
  font-weight: bold;
  color: #17c1e8;
}

.content-dasboard h2.form-header {
  color: #3e3e3e;
}

.content-dasboard button.btn.btn-primary.w-100 {
  background: #f3797e;
  border: none;
}

.ant-modal-content {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 40px 40px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.ant-modal-content button.btn.btn-primary.w-100 {
  background: #f3797e;
  height: 40px;
  border: none;
}

.ant-modal-content input {
  background: #f5f7ff !important;
  height: 40px;
  border: none !important;
  font-size: 14px !important;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px !important;
}

.ant-modal-content label.form-label {
  margin: 0 0 3px 0;
}

.ant-modal-content {
  padding: 0 !important;
}

.ant-modal-content .ant-modal-header {
  background: #17c1e8 !important;
  padding: 15px 15px !important;
  margin: 0 !important;
}

.ant-modal-content .ant-modal-title {
  color: #fff !important;
}

/* .ant-modal-content svg {
  fill: white;
} */

.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal {
  top: 0 !important;
  padding: 0 !important;
}

.background-radial-gradient {
  overflow: hidden;
  position: relative;
}

.background-radial-gradient .d-flex.justify-content-center {
  width: 100%;
}

.background-radial-gradient .d-flex.justify-content-center .row.position-absolute {
  position: static !important;
  padding: 0 !important;
}

html body .navbar_connect_button_css button.iekbcc0.iekbcc9.ju367v4.ju367va3.ju367vn.ju367vei.ju367vfu.ju367va.ju367v16.ju367v1h.ju367v8u._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i6 {
  background: transparent !important;
  box-shadow: none !important;
}

.verify_otp_css {
  background: white !important;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.otp_custom_css {
  background: white !important;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.icons-svg {
  margin: 0px 10px 4px 0px !important;
}

.rounded-circle {
  width: 40px;
  height: 40px;
  object-fit: cover;
  margin: auto;
  display: block;
}

.rounded-circleProfile {
  width: 80px;
  height: 80px;
  object-fit: cover;
  /* margin: auto; */
  border-radius: 10%;
  display: block;
}

.profile-card p {
  font-size: 12px;
  margin: 2px 0 0 0;
}

.abs-mobile {
  font-size: 14px;
}

@media (max-width: 1024px) {
  .abs-mobile {
    font-size: 10px;
    word-break: break-all;
    position: absolute;
    width: auto;
    left: 240px;
    right: 0;
    bottom: 10px;
    text-align: center;
  }

  .dasboard-all.top-header {
    padding: 5px 0 5px 0 !important;
    position: relative;
  }
}

.Navbar_css a {
  text-decoration: none;
  color: black;
}

/* ******************************** */

.chatai_icon_css {
  color: #17c1e8 !important;
}

.delete_chatai_icon_css {
  color: red;
}

.main-div-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 20px;
  left: 0;
  z-index: 99 !important;
}

.main-div-msgchatbot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 29px;
  left: 0;
}

.input-box {
  width: 100%;
  max-width: 820px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.input-group-text {
  background-color: #f1f1f1;
  border: none;
  border-radius: 10px;
}

.form-control {
  border: none;
  background-color: #f7fdfc;
  border-radius: 10px;
}

.form-control:focus {
  box-shadow: none;
  outline: none;
}

ul.first-button {
  display: flex;
  list-style: none;
  background: #f8f9fa !important;
  margin: 10px 0px 0px 0px;
  border-radius: 10px;
  padding: 0px 5px;
  gap: 5px;
}

ul.second-button {
  display: flex;
  list-style: none;
  background: #e2e8f0;
  margin: 10px 0px 0px 9px;
  border-radius: 10px;
  padding: 5px 12px;
}

ul.third-button {
  display: flex;
  list-style: none;
  margin: 10px 0px 0px 9px;
  border-radius: 10px;
  padding: 5px 12px;
  float: right;
  background: #e2e8f0;
}

ul.first-button li {
  padding: 7px 10px;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.first-btn {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

ul.first-button li:hover {
  background: #17c1e8 !important;
  border-radius: 10px !important;
  color: #fff !important;
}

ul.second-button:hover {
  background: #17c1e8 !important;
  color: #fff !important;
}

ul.third-button:hover {
  background: #17c1e8 !important;
  color: #fff !important;
}

ul.first-button,
.second-button,
.third-button li i {
  color: #5c5b5b;
}

.active_css_aichatbot {
  background: #17c1e8 !important;
  color: white;
}

.right-ms {
  display: flex;
  justify-content: flex-end;
}

.left-ms {
  display: flex;
  justify-content: flex-start;
}
.chat-wrapper {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 10px;
  background-color: #17c1e8;
  font-size: 12px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
}

.copy-button:hover {
  background-color: #0056b3;
}

.chat-container {
  padding: 10px;
  border-radius: 8px;
  margin-top: 40px;
}

@media (max-width: 767px) {
  .abs-mobile {
    font-size: 14px;
  }

  .tiktok_css {
    margin-top: 20% !important;
    width: 100% !important;
  }

  .abs-mobile {
    font-size: 10px;
    word-break: break-all;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    text-align: center;
  }

  .footer_css h1 {
    margin-left: 0px !important;
  }
}

.footer_css h1 {
  margin-left: 270px;
  color: #000;
  transition: 0.6s;
}

.footer_css_transition h1 {
  transition: 1s !important;
  color: #000;
}

.profile-pic {
  width: 200px;
  height: 200px;
}

ul.text-muted {
  display: flex;
  font-size: 19px;
  gap: 40px;
  list-style: none;
  padding: 0px;
}

.text-list {
  margin-bottom: 10px;
}

.label {
  font-size: 18px;
  color: #888;
  font-family: math;
}

.detail {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-top: 5px;
  font-family: monospace;
}


h3.profile-detail {
  font-size: 1.5rem;
}

.text-muted {
  font-size: 0.9rem;
}

img {
  object-fit: cover;
}

ul.nav.nav-tabs {
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  border-bottom: none;
}

a.nav-link.active {
  border-left: none;
  border-right: none;
  border-radius: 0;
  border-top: 2px solid #000 !important;
}

.new-post-btn {
  width: 50px;
  height: 50px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.head-top {
  border-top: 1px solid #ddd;
  text-align: center;
}

#radius-shape-1 {
  display: none;
}

#radius-shape-2 {
  display: none;
}

.d-flex.justify-content-center.gap-3.resp_add_social_info {
  flex-wrap: wrap;
}

.d-flex.justify-content-center.gap-3.resp_add_social_info .insta_button_css {
  padding: 5px 15px !important;
}

.content-dasboard .card {
  background: #fff;
  box-shadow: rgb(149 157 165 / 7%) 0px 8px 24px;
  padding: 30px 30px !important;
  border: none !important;
  outline: none !important;
  border-color: transparent !important;
  border-radius: 18px;
}

.card.siz-card {
  max-width: 580px;
  margin: auto;
  margin-left: 0 !important;
}

.card.siz-card .form-control {
  min-height: 50px;
}

.card.siz-card .btn.btn-primary.w-100 {
  min-height: 50px;
}

.flex-repet {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.flex-repet .ant-card.ant-card-bordered {
  width: 100%;
  margin: 0 0 30px 0;
}

div#post {
  padding: 0;
}

.card-body.p-4.insta-body .form-control[type="file"] {
  /* height: 35px !important; */
  min-height: 35px !important;
}

.recording_css button {
  border: navajowhite;
  padding: 10px;
  border-radius: 10px;
}

.text_area_css__post {
  width: 100% !important;
}

.flex-repet .ant-ribbon-wrapper {
  width: 30%;
  margin: 0 15px;
}

.flex-repet .ant-card.ant-card-bordered {
  height: calc(100% - 30px);
}
.Answer_cssAiagent.my-4 img {
  height: auto !important;
  width: 100% !important;
  max-width: 91% !important;
}

.Answer_cssAiagent.my-4 img.AI-image {
  max-width: 65px !important;
}
p.no-dt {
  font-size: 25px;
  text-align: center;
  margin-top: 15px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(217, 231, 245, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(217, 231, 245, 0.3);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(207, 255, 255, 0.3);
}

.chart {
  position: relative;
  width: 100%;
  height: auto;
}

.history_user_css {
  cursor: pointer;
  width: 25px;
  height: 21px;
}

@media (max-width: 768px) {
  .chart canvas {
    width: 100% !important;
    height: auto !important;
  }

  .video_resp_css {
    width: 198px !important;
    height: 160px !important;
  }
}

.admin_resp_css {
  margin-left: 0 !important;
}

.text_resp_css {
  word-wrap: break-word !important;
  white-space: normal !important;
}

.icon_resp_css {
  margin-left: 0 !important;
}

.Answer_cssAiagent {
  max-width: 100% !important;
}

.submenu {
  padding-left: 20px;
  list-style-type: none;
}

.submenu li {
  margin-top: 5px;
}

.submenu-caret {
  margin-left: 5px;
}

@media (max-width: 1024px) {
  .dasboard-all.pages {
    min-height: calc(100vh - 165px);
  }
  .profile-pic {
    width: 140px;
    height: 140px;
  }
  .profile-name.col-lg-8 {
    padding-top: 18px !important;
  }
  .flex-repet .ant-ribbon-wrapper {
    width: 45%;
  }
  .card.siz-card {
    max-width: 100%;
  }
  .background-radial-gradient-instagram.overflow-hidden div {
    max-width: 100% !important;
  }
  .ant-tabs-content.ant-tabs-content-top div {
    max-width: 100% !important;
  }
  .flex-repet .ant-ribbon-wrapper {
    width: 100%;
  }
  .message_cssAiagent {
    height: calc(100vh - 350px);
  }
  .Chat_bot_css {
    height: calc(100vh - 224px);
  }
  .chart {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
  .pad-privasy {
    padding: 65px 15px 30px 15px !important;
  }

  .resp_progress_css {
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  .ant-progress-text {
    width: 100% !important;
  }
  .privacyPolicy_css h1 {
    font-size: 28px !important;
  }
  .privacyPolicy_css h1 {
    font-size: 28px !important;
  }
  .privacyPolicy_css {
    padding: 22px 22px;
  }
  .main-div-msg .input-box button .mx-2 {
    font-size: 14px;
  }
  .privacyPolicy_css {
    padding: 22px 22px;
  }
  .side-bar {
    overflow: unset;
  }
  .tog-bt {
    position: relative !important;
    top: 12px;
  }
  .dasboard-all.top-header {
    padding: 15px 0 15px 0;
  }
  .header-flex ul li.d-flex.align-items-center.flex-wrap {
    display: flex;
    justify-content: flex-end;
    margin: 0 0 0px 0;
  }
  .wallet-address {
    margin: 0 10px 0 0;
  }
  .header-flex ul {
    align-items: flex-start;
  }
  .header-flex ul li {
    margin: 0 15px 0px 0;
  }
  .flex-repet .ant-card.ant-card-bordered {
    width: 100% !important;
  }

  .text_area_css__post {
    width: 100% !important;
  }

  .flex-repet .ant-ribbon-wrapper {
    width: 100%;
    margin: 0 0 0px 0;
  }

  .card-body.p-4.insta-body .form-control[type="file"] {
    /* height: 35px !important; */
    min-height: 35px !important;
    width: 100% !important;
  }

  .first-box {
    width: 100%;
    margin-bottom: 15px;
  }

  button.w-100.p-2.gmail_button_css {
    width: 100% !important;
  }

  .content-dasboard ul.text-muted {
    flex-wrap: wrap;
    gap: 3px;
  }

  .content-dasboard ul.text-muted li {
    width: 100%;
  }

  .content-dasboard .container.mt-4 {
    padding-left: 0;
    padding-right: 0;
  }

  .content-dasboard .container.mt-5 {
    padding-left: 0;
    padding-right: 0;
  }

  .privacyPolicy_css {
    padding: 30px 30px;
  }
  .message_cssAiagent {
    height: calc(100vh - 450px);
  }

  .input-box {
    width: 90%;
  }

  .card.siz-card {
    padding: 0px !important;
  }

  .content-dasboard .card {
    box-shadow: none;
    padding: 15px 15px !important;
  }

  .profile-section .card-body {
    padding: 15px 30px;
  }
  .chart {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 991px) {
  .ant-tabs-content.ant-tabs-content-top div {
    max-width: 100% !important;
  }
  ul.text-muted {
    flex-wrap: wrap;
    gap: 15px;
  }
  ul.text-muted li {
    width: 100%;
  }
  .chart {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1190px) {
  .dasboard-all.pages {
    min-height: calc(100vh - 165px);
  }
  .message_cssAiagent {
    height: calc(100vh - 390px);
  }
  .Chat_bot_css {
    height: calc(100vh - 224px);
  }
  .chart {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 1390px) {
  .dasboard-all.pages {
    min-height: calc(100vh - 124px);
  }
  .message_cssAiagent {
    height: calc(100vh - 337px);
  }
  .Chat_bot_css {
    height: calc(100vh - 224px);
  }
  .chart {
    width: 100%;
    height: auto;
  }

  .resp_login_img {
    width: 100%;
  }
}

.left-align-table .ant-table-cell {
  text-align: left !important;
}

.chat-answer {
  color: black;
  display: flex;
  align-items: center;
  height: 100%;
}

.AI-image {
  width: 55px;
  border-radius: 50%;
}

.loading {
  top: 50%;
  z-index: 9999999 !important;
  height: 50px;
  display: flex;
  align-items: center;
}

.obj {
  width: 6px;
  height: 0px;
  background: #17a2b8;
  margin: 0 3px;
  border-radius: 10px;
  animation: loading 1.2s infinite;
}

.obj:nth-child(2) {
  animation-delay: 0.15s;
}

.obj:nth-child(3) {
  animation-delay: 0.3s;
}

.obj:nth-child(4) {
  animation-delay: 0.45s;
}

.obj:nth-child(5) {
  animation-delay: 0.6s;
}

.obj:nth-child(6) {
  animation-delay: 0.75s;
}

.obj:nth-child(7) {
  animation-delay: 0.9s;
}

.obj:nth-child(8) {
  animation-delay: 1.05s;
}

@keyframes loading {
  0% {
    height: 0;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 0;
  }
}

.wallet-container {
  display: inline-block;
  position: relative;
}

.wallet-address {
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  border: 1px solid transparent;
}

.wallet-address:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-color: #003d7a;
}

.wallet-address:focus {
  outline: none;
}

.copy-message {
  position: absolute;
  top: 40px;
  left: 100px;
  transform: translateX(-50%);
  margin-top: 8px;
  background-color: #19c742;
  color: #fff;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

label.ant-form-item-required {
  min-width: 100px !important;
}

/* ////adminnnnnnnnnnnnnnnnnnn */

.login-containeradmin {
  display: flex;
  width: 70%;
  max-width: 1000px;
  background-color: #001819;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.login-containeradmin {
  display: flex;
  width: 50%;
  position: relative;
  top: 100px;
  justify-content: center !important;
  margin: auto;
  background-color: #001819;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
}

.login-leftadmin {
  width: 50%;
  background-color: #022a2e;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-leftadmin h1 {
  color: #ffffff;
  margin-top: 20px;
}

.logoadmin img {
  width: 100px;
  height: auto;
}

.login-rightadmin {
  width: 50%;
  background-color: #0f3033;
  color: white;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-rightadmin h2 {
  margin-bottom: 10px;
}

.login-rightadmin p {
  margin-bottom: 20px;
}

.formadmin {
  display: flex;
  flex-direction: column;
}

.formadmin input {
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

.formadmin button {
  padding: 10px;
  background-color: #ff6600;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
}

.formadmin button:hover {
  background-color: #ff8533;
}

.formadmin a {
  color: #ff6600;
  text-decoration: none;
}

.formadmin a:hover {
  text-decoration: underline;
}

.Upload_image_css {
  display: flex !important;
  align-items: center !important;
}

.upload_btn_css {
  height: 35px !important;
}

.main-div-msg .ant-upload-list-item.ant-upload-list-item-undefined {
  height: 35px !important;
  margin: 0 0 0 5px !important;
}

.main-div-msg .ant-upload-list-item.ant-upload-list-item-undefined {
  height: 35px !important;
  margin: 0 0 0 5px !important;
}
.main-div-msg .first-button .dropdown.my-3 {
  margin: 0 !important;
}
.main-div-msg .first-button {
  margin: 0 !important;
  background: #fff !important;
}
.main-div-msg .first-btn {
  background: #fff !important;
}
